import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0caa383b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page_container_with_tb_padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThreeDesire = _resolveComponent("ThreeDesire")!
  const _component_TenValue = _resolveComponent("TenValue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ThreeDesire),
    _createVNode(_component_TenValue)
  ]))
}